import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { TextInput } from '@meetup/swarm-components';
import Example from '../../components/examples/textInput';
import PropsTable from '../../components/propstable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`TextInput`}</h1>
    <hr></hr>
    <p>{`A wrapper for an html `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` element with an optional `}<inlineCode parentName="p">{`<label>`}</inlineCode>{` tag.`}</p>
    <br />
    <br />
    <h3>{`Snippet`}</h3>
    <hr></hr>
    <p>{`Use this interactive snippet to try the component:`}</p>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<TextInput id="input" value="phife dawg two" />
`}</code></pre>
    <br />
    <br />
    <h3>{`Props`}</h3>
    <hr></hr>
    <br />
    <PropsTable propMetaData={TextInput.__docgenInfo} mdxType="PropsTable" />
    <br />
    <br />
    <h3>{`a11y`}</h3>
    <hr></hr>
    <br />
    <p>{`Keyboard interactions: `}<strong parentName="p">{`n/a`}</strong></p>
    <br />
Must supply a <b>label</b> html tag with an <b>`htmlFor`</b> React attribute that equals the value of the textInput's <b>id</b>.
    <br />
    <br />
    <h3>{`Examples`}</h3>
    <hr></hr>
    <br />
    <Example mdxType="Example" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      